import Text from "../../../../i18n/text"

const servicesMonth = [
    {
        name: <Text id='CLEANING' />,
        description1: <Text id='For an apartment of up to 60 square meters'/>,
        description2: <Text id='4 regular cleanings' />,
        price: 340,
    },
    {
        name: <Text id='CLEANING Plus' />,
        description1: <Text id='For an apartment of up to 60 square meters' />,
        description2: <Text id='1 general cleaning + 4 regular cleanings' />,
        price: 390,
    },
    {
        name: <Text id='Help around the house' />,
        description1: <Text id='8 times a month' />,
        description2: <Text id='2 hours a day' />,
        price: 700,
    },
    {
        name: <Text id='Masseur' />,
        description1: <Text id='8 times a month' />,
        description2: <Text id='1 academic hour a day' />,
        price: 680,
    },
    {
        name: <Text id='BABYSITTER / NURSE' />,
        description1: <Text id='20 days a month' />,
        description2: <Text id='4 hours a day' />,
        price: 4320,
    },
    {
        name: <Text id='Cook' />,
        description1: <Text id='20 days a month' />,
        description2: <Text id='1 time a day' />,
        price: 2700,
    },
    {
        name: <Text id='Tutor / Music teacher / Speech therapist / Defectologist' />,
        description1: <Text id='8 times a month' />,
        description2: <Text id='without an academic degree' />,
        price: 220,
    },
    {
        name: <Text id='Tutor / Music teacher / Speech therapist / Defectologist' />,
        description1: <Text id='8 times a month' />,
        description2: <Text id='with an academic degree' />,
        price: 340,
    },
]

export default servicesMonth