import { useState, useCallback } from 'react'
import styles from './index.module.css'
import services from './services'
import Count from '../../Count'
import Text from '../../../../i18n/text'

const Card = ({ id }: { id: number }) => {
    const [visions] = useState(services[id].max === 1 ? false : true)

    const [values, setValues] = useState([
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
    ]);

    const countOnChange = useCallback((id, count) => {
        setValues(v => ({
            ...v,
            [id]: count,
        }))
    }, [])

    return (
        <div>
            <div className={styles.background} />
            <div className={styles.card}>
                <div className={styles.cardWrapper}>
                    <h1 className={styles.cardTitle}>{services[id].name}</h1>
                    <span className={styles.cardPrice}>{services[id].price * values[id]} <Text id='GEL' /></span>
                    <span className={styles.cardText}>{services[id].description}</span>
                    <Count onChange={countOnChange} defaultCount={values[id]} id={id} max={services[id].max} vision={visions} />
                </div>
            </div>
        </div>
    )
}

export default Card