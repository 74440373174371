import React, { memo, useState } from "react"
import styles from './index.module.css'
import Text from "../../../i18n/text"

const Burger = memo(() => {
    const [burgerActive, setBurgerActive] = useState(false)

    const Scroll = (id: string) => {
        const element = document.getElementById(id);
        if (element != null) {
            if (window.innerWidth > 1960) {
                const yOffset = -200
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            } else if (window.innerWidth < 1960 && window.innerWidth > 800) {
                const yOffset = -130
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            } else {
                const yOffset = -100
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }
    }

    return (
        <div className={styles.burger} onClick={() => burgerActive ? setBurgerActive(false) : setBurgerActive(true)}>
            <div className={styles.burgerWrapper}>
                <div className={styles.burgerLine1}></div>
                <div className={burgerActive ? styles.burgerLine2 : styles.burgerLine1}></div>
                <div className={burgerActive ? styles.burgerLine3 : styles.burgerLine1}></div>
            </div>
            <div className={burgerActive ? styles.burgerMenuActive : styles.burgerMenu}>
                <h1 className={styles.menuTitle}><Text id='Menu' /></h1>
                <button className={styles.menuLink} onClick={() => Scroll('Cleaning services')}>
                    <Text id='Cleaning services' />
                </button>
                <button className={styles.menuLink} onClick={() => Scroll('Household services')}>
                    <Text id='Household services' />
                </button>
                <button className={styles.menuLink} onClick={() => Scroll('Additional education')}>
                    <Text id='Additional education' />
                </button>
                <button className={styles.menuLink} onClick={() => Scroll('Care services')}>
                    <Text id='Care services' />
                </button>
                <button className={styles.menuLink} onClick={() => Scroll('Monthly services')}>
                    <Text id='Monthly services' />
                </button>
            </div>
        </div>
    )
})

export default Burger