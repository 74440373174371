import React, { memo } from "react"
import styles from './index.module.css'

const Privacy = memo(() => {
    return (
        <div className={styles.section}>
            <h1 className={styles.title}>
                Privacy policy
            </h1>
            <div className={styles.textBlock}>
                <p className={styles.text}>
                    The procedure for collecting, storing, processing
                    and using personal data in accordance with GDPR requirements
                    ( <a href="https://ogdpr.eu/ru" target="_blank_">https://ogdpr.eu/ru</a> )
                </p>
                <p className={styles.text}>
                    The collection of personal data
                    (full name, contact information, passport data,
                    data of the Certificate of registration of individual
                    entrepreneurs, place of residence and others) is carried
                    out through official collection points, namely:
                    e-mail <a href="mailto:gervice.ge@gmail.com" target="_blank_">gervice.ge@gmail.com</a>, google forms
                    on google drive account,
                    website <a href="https://gervice.ge/" target="_blank_">gervice.ge</a>, <a href="https://t.me/ipgeorgiabot" target='_blank_'>the telegram-bot</a> developed by us and other
                    officially registered information collection sites.
                </p>
                <p className={styles.text}>
                    Information is stored only on servers and computers belonging to
                    the Gervice project and its organizers with the possible use of
                    technologies that ensure proper protection and storage of PD.
                </p>
                <p className={styles.text}>
                    The processing of personal data is carried out after they are received
                    at the official collection points only by the project organizers.
                    Unauthorized persons are not allowed to process personal data.
                </p>
                <p className={styles.text}>
                    It is allowed to use personal data only directly for the implementation
                    of the goals of the Gervice project.
                </p>
                <p className={styles.text}>
                    The protection of personal data is ensured both by protecting the
                    data implemented when creating the Project website, and by guaranteeing
                    personal security on the part of the Project organizers who have access to them.
                    IE Hi-Tech Business Hub  is directly responsible for the protection and
                    processing of personal data, which, if necessary, is authorized to involve other
                    Project organizers and/or a specialized organization for these tasks.
                </p>
                <p className={styles.text}>
                    Personal data is deleted from the Project database at the request of the
                    client/employee/partner.
                </p>
                <p className={styles.text}>
                    The personal data report is kept in any form in the form of registers
                    approved by the Project organizers, which necessarily include: a list of
                    personal data, the date of receipt of personal data, the date of inclusion
                    in the register of personal data, the date of deletion of personal data,
                    the date of change of personal data, the date of transfer of personal data
                    to third parties and the legal basis for such transfer, Note.
                </p>
            </div>
        </div>
    )
})

export default Privacy