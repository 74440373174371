import styles from './index.module.css'
import { ReactComponent as Plus } from './images/plus.svg'
import { ReactComponent as Minus } from './images/minus.svg'
import { useCallback, useState } from 'react'
import Text from '../../../i18n/text'

type Props = {
    onChange: (id: number, count: number) => void
    defaultCount: number
    id: number
    max: number
    vision: boolean
}

const Count = ({ onChange, defaultCount, id, max, vision }: Props) => {
    const [count, setCount] = useState<number>(defaultCount)

    const def = useCallback(() => {
        onChange(id, count)

        setCount(count)
    }, [onChange, id, count])

    const decrease = useCallback(() => {
        onChange(id, count - 1)

        setCount(count - 1)
    }, [onChange, id, count])

    const increase = useCallback(() => {
        onChange(id, count + 1)

        setCount(count + 1)
    }, [onChange, id, count])

    return (
        <div className={vision ? styles.count : styles.countHide}>
            <div className={styles.countControls}>
                <button type='button' className={styles.countDown} onClick={count > 1 ? decrease : def}>
                    <Minus className={styles.countImg} />
                </button>
                <p className={styles.countInput}>{count}</p><p>{(count === 1) ? <Text id='hour' /> : (count < 5) ? <Text id='hours1' /> : <Text id='hours2' />}</p>
                <button type='button' className={styles.countUp} onClick={count < max ? increase : def}>
                    <Plus className={styles.countImg} />
                </button>
            </div>
        </div>
    )
}

export default Count;