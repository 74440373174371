import React, { memo } from "react"
import styles from './index.module.css'
import { ReactComponent as Click } from './images/click.svg'
import Text from "../i18n/text"

const ButtonPartner = memo(() => {
    return (
        <a href="https://t.me/ipgeorgiabot"
            target="_blank_">
            <button className={styles.button}>
                <span className={styles.buttonText}>
                    <Text id='Become a client' />
                </span>
                <Click className={styles.click} />
            </button>
        </a>
    )
})

export default ButtonPartner