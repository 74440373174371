import React, { memo } from "react"
import Header from '../Header'
import Footer from '../Footer'
import CatalogMenu from './components/CatalogMenu'
import Title from "./components/Title"

const Catalog = memo(() => {
    return (
        <section>
            <Header />
            <Title />
            <CatalogMenu />
            <Footer />
        </section>
    )
})

export default Catalog