import styles from './index.module.css'
import servicesCleaning from './servicesCleaning'
import { useState } from 'react'
import { ReactComponent as Check } from './images/check.svg'
import { ReactComponent as Close } from './images/close.svg'
import { ReactComponent as RightArrow } from './images/rightArrow.svg'
import { ReactComponent as LeftArrow } from './images/leftArrow.svg'
import Text from '../../../../i18n/text'

const CardMonth = ({ id }: { id: number }) => {
    const [cardVisible, setCardVisible] = useState(true)
    const [page1Visible, setPage1Visible] = useState(false)
    const [page2Visible, setPage2Visible] = useState(false)
    const [page3Visible, setPage3Visible] = useState(false)
    const [page4Visible, setPage4Visible] = useState(false)

    const list = (name: Array<JSX.Element>) => name.map((i) => {
        return (
            <div className={styles.checkTextWrapper}>
                <Check className={styles.checkImg} />
                <p className={styles.checkText}>
                    {i}
                </p>
            </div>
        )
    })

    return (
        <div>
            <div className={styles.background} />
            <div className={cardVisible ? styles.card : styles.cardHidden}>
                <div className={styles.cardWrapper}>
                    <h1 className={styles.cardTitle}>{servicesCleaning[id].name}</h1>
                    <span className={styles.cardPrice}>{servicesCleaning[id].price} <Text id='GEL' /></span>
                    <span className={styles.cardText}>{servicesCleaning[id].description}</span>
                    <button onClick={() => cardVisible ? (setCardVisible(false), setPage1Visible(true)) : setCardVisible(true)}
                        className={styles.buttonMore}>
                        <Text id='DETAILS' />
                    </button>
                </div>
            </div>
            <div className={page1Visible ? styles.cardBack : styles.cardBackHidden}>
                <div className={styles.checkWrapper}>
                    <p className={styles.checkTitle}>
                        {servicesCleaning[id].titles[0]}
                    </p>
                    {list(servicesCleaning[id].page1)}
                    <RightArrow onClick={() => cardVisible ? setCardVisible(false) : (setPage2Visible(true), setPage1Visible(false))}
                        className={styles.rightArrow} />
                    <Close onClick={() => cardVisible ? setCardVisible(false) : (setCardVisible(true), setPage1Visible(false))}
                        className={styles.buttonClose} />
                </div>
            </div>
            <div className={page2Visible ? styles.cardBack : styles.cardBackHidden}>
                <div className={styles.checkWrapper}>
                    {list(servicesCleaning[id].page2)}
                    <LeftArrow onClick={() => cardVisible ? setPage1Visible(false) : (setPage1Visible(true), setPage2Visible(false))}
                        className={styles.leftArrow} />
                    <RightArrow onClick={() => cardVisible ? setPage1Visible(false) : (setPage3Visible(true), setPage2Visible(false))}
                        className={styles.rightArrow} />
                    <Close onClick={() => cardVisible ? setCardVisible(false) : (setCardVisible(true), setPage2Visible(false))}
                        className={styles.buttonClose} />
                </div>
            </div>
            <div className={page3Visible ? styles.cardBack : styles.cardBackHidden}>
                <div className={styles.checkWrapper}>
                    <p className={styles.checkTitle}>
                        {servicesCleaning[id].titles[1]}
                    </p>
                    {list(servicesCleaning[id].page3)}
                    <RightArrow onClick={() => cardVisible ? setPage1Visible(false) : (setPage4Visible(true), setPage3Visible(false))}
                        className={styles.rightArrow} />
                    <LeftArrow onClick={() => cardVisible ? setCardVisible(false) : (setPage2Visible(true), setPage3Visible(false))}
                        className={styles.leftArrow} />
                    <Close onClick={() => cardVisible ? setCardVisible(false) : (setCardVisible(true), setPage3Visible(false))}
                        className={styles.buttonClose} />
                </div>
            </div>
            <div className={page4Visible ? styles.cardBack : styles.cardBackHidden}>
                <div className={styles.checkWrapper}>
                    <p className={styles.checkTitle}>
                        {servicesCleaning[id].titles[2]}
                    </p>
                    {list(servicesCleaning[id].page4)}
                    <div className={styles.add}>
                        <h1><Text id='Additional services:' /></h1>
                        <p className={styles.addText}><Text id='Washing dishes: 30 minutes - 20 GEL' /></p>
                    </div>
                    <LeftArrow onClick={() => cardVisible ? setCardVisible(false) : (setPage2Visible(true), setPage4Visible(false))}
                        className={styles.leftArrow} />
                    <Close onClick={() => cardVisible ? setCardVisible(false) : (setCardVisible(true), setPage4Visible(false))}
                        className={styles.buttonClose} />
                </div>
            </div>
        </div>
    )
}

export default CardMonth