import { LOCALES } from '../constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    [LOCALES.RUSSIAN]: {
        'Easier': 'Проще,',
        'than': 'чем',
        'You think!': 'Вы думаете!',
        'More accessible': 'Доступнее,',
        'You are expecting!': 'Вы ожидаете!',
        'A lot of specialists in just one click!': 'Множество специалистов всего в один клик!',
        'Catalog': 'Каталог',

        'Become a client': 'Стать клиентом',
        'Get a job': 'Получить работу',

        'Our advantages': 'Наши преимущества',
        'we are the owners of several telegram': 'мы являемся владельцами нескольких',
        'channels with a target audience of more': 'телеграм каналов с целевой аудиторией',
        'than 10,000 subscribers': 'более 10 000 подписчиков',
        'our team members have professional': 'члены нашей команды имеют опыт',
        'experience «in turnkey» construction': 'профессионального строительства «под ключ»',
        'we cooperate with a large number of': 'мы сотрудничаем с большим количеством',
        'companies and specialists in various fields': 'компаний и специалистов в различных областях',

        'Some information about us': 'Немного информации о нас',
        'About us': 'О нас',
        'Why us?': 'Почему именно мы?',
        'About1': 'Наша команда объединяет специалистов различных сфер деятельности. Мы продвигаем высококачественные строительные работы и услуги на грузинском рынке вместе с нашими партнерами. Также все виды услуг по организации комфортной жизни в Грузии. Мы подбираем специалистов по запросам клиентов. Мы занимаемся развитием информационных технологий для организации высококачественных услуг в области комплексных бытовых услуг.',
        'Areas of work:': 'Области работы:',
        'About2': 'Все, что связано со строительными работами и услугами. Услуги по организации комфортной жизни в Грузии: в области строительства, ремонта, уборки, работы по дому (электрик, сантехник, помощь по дому, повар, швея, грузчик и т. д.), дополнительное образование и обслуживание (репетиторы, вокал, музыка, медсестры, няни), IT-школы, Продленка и т.д. А также оказываем информационно-правовую поддержку различных бизнес-проектов и их поддержку с использованием информационных технологий.',
        'Have more questions?': 'Еще остались вопросы?',
        'QuestionsText': 'Вы можете посетить наш основной веб-сайт или связаться с нашей службой технической поддержки и уточнить все интересующие вас детали',
        'Telegram Support': 'Поддержка в Telegram',
        'or': 'или',
        'Our main Website': 'Наш основной веб-сайт',
        'Information': '',

        'Our social networks': 'Наши социальные сети',
        'Place an order right now': 'Оформите заказ прямо сейчас',
        'OrderText': 'Мы обеспечиваем нашим партнёрам оперативный поиск необходимых специалистов, которые выполняют работу качественно, в срок и по приемлемым ценам',
        'LogoText': 'Gervice — качественный сервис в сфере строительства',

        'CatalogMes1': 'Мы разработали для Вас направление Gervice - Организация комфортной жизни в Грузии! Gervice предоставляет различные услуги для организации Вашей комфортной жизни в Грузии. Это как бытовые услуги на дому: Клининг, Мастер на час, Мелкий ремонт, Помощь по хозяйству, Услуги няни и сиделки, Приготовление пищи, Услуги репетиторов, логопедов, дефектологов, Массаж, ЛФК и иные услуги, которые Вам необходимы.Так и услуги  в сфере дополнительного образования и обслуживания: IT - школа, Продленка и иные услуги. Мы подбираем для Вас лучших специалистов и организуем их работу, гарантируем качество оказания услуг, чтобы Вам не приходилось задумываться о том, как комфортно организовать свой бизнес и свою жизнь в Грузии.Если на данный момент у нас не будет необходимого специалиста по запрошенному направлению в Вашем регионе мы обязательно его подберем лично для Вас!',
        'CatalogMes2': '*Стоимость строительных услуг и иных услуг, не перечисленных в Прайсе, определяется по индивидуальной договоренности с клиентом',
        'Cleaning services': 'Услуги по уборке',
        'Household services': 'Бытовые услуги',
        'Additional education': 'Дополнительное образование',
        'Care services': 'Услуги по уходу',
        'Monthly services': 'Ежемесячные услуги',
        'Menu': 'Меню',

        'REGULAR CLEANING': 'Обычная уборка',
        'GENERAL CLEANING': 'Генеральная уборка',
        'For an apartment of up to 60 square meters': 'Для однокомнатной квартиры 60 кв метров',
        'THE WHOLE APARTMENT': 'ВСЯ КВАРТИРА',
        'KITCHEN': 'КУХНЯ',
        'BATHROOM / TOILET': 'ВАННАЯ / ТУАЛЕТ',
        'Dust off the walls and ceiling': 'Обеспылить стены и потолок',
        'Remove dust and eliminate dirt on all surfaces': 'Обеспылить и устранить загрязнения на всех поверхностях',
        'Dust off and wash the lighting fixtures': 'Обеспылить и помыть осветительные приборы',
        'Wash the floor': 'Помыть пол',
        'Vacuum the carpet': 'Пропылесосить ковёр',
        'Clean household appliances outside': 'Очистить бытовую технику снаружи',
        'Wash the mirrors': 'Помыть зеркала',
        'Wash the entrance and interior doors': 'Помыть входную и межкомнатные двери',
        'Wash the furniture': 'Помыть мебель',
        'Wash the sink': 'Помыть раковину',
        'Wash the countertop': 'Помыть столешницу',
        'Wash the cabinets from the inside': 'Помыть шкафы изнутри',
        'Wash the stove': 'Помыть плиту',
        'Eliminate traces of rust, water stone and lime plaque': 'Устранить следы ржавчины, водного камня и известкового налета',
        'Wash the tile and clean the seams': 'Помыть кафельную плитку и очистить швы',
        'Eliminate local contamination on the walls': 'Устранить локальные загрязнения на стенах',
        'Make the bed': 'Застелить постель',
        'Clean the glazed balcony, loggia (vacuum, wipe the dust)': 'Убрать застекленный балкон, лоджию (пропылесосить, обеспылить)',
        'Wash the windows (one window, the rest – for an additional fee)': 'Помыть окна (одно окно, остальные – за дополнительную оплату)',
        'Wash the stove/eliminate traces of rust': 'Помыть плиту/устранить следы ржавчины',
        'Wash the refrigerator without defrosting': 'Помыть холодильник без разморозки',
        'Clean the hood/eliminate traces of grease': 'Почистить вытяжку/устранить следы жира',
        'DETAILS': 'ПОДРОБНЕЕ',
        'Additional services:': 'Дополнительные услуги:',
        'Washing dishes: 30 minutes - 20 GEL': 'Мойка посуды - 30 минут - 20 лари',

        'GEL': 'лари',

        'Plumbing works': 'Сантехнические работы',
        'Calling a specialist for minor plumbing work': 'Вызов специалиста для выполнения мелких сантехнических работ',
        'Electrician': 'Электрика',
        'Calling a specialist for minor electrical work': 'Вызов специалиста для выполнения мелких электромонтажных работ',
        'Cook': 'Повар',
        'Purchase of products and preparation of three dishes': 'Закупка продуктов и приготовление трех блюд',
        'Help around the house': 'Помощь по дому',
        'Calling a specialist to provide assistance around the house': 'Вызов специалиста для помощи по дому',
        'Loader': 'Погрузочные работы',
        'Calling one specialist and ascent / descent to one floor': 'Вызов одного специалиста и подъем/спуск на 1 этаж',
        'Seamstress': 'Швея',
        'Small work (shorten trousers, insert zipper)': 'Мелкая работа (укоротить брюки, вставить молнию)',
        'Tutor / Music teacher / Speech therapist / Defectologist': 'Репетитор / Преподаватель музыки / Логопед / Дефектолог',
        'without an academic degree': 'без ученой степенью',
        'with an academic degree': 'с ученой степенью',
        'Babysitter': 'Няня',
        'Calling a specialist for child care': 'Вызов специалиста по уходу за ребенком',
        'Nurse': 'Сиделка',
        'Calling a specialist to take care of a person': 'Вызов специалиста для ухода за человеком',
        'Masseur': 'Массажист',
        'Providing a full range of massage services': 'Предоставление полного спектра массажных услуг',

        'CLEANING': 'Уборка',
        '4 regular cleanings': '4 обычные уборки',
        'CLEANING Plus': 'Уборка Плюс',
        '1 general cleaning + 4 regular cleanings': '1 генеральная уборка + 4 обычных уборки',
        '8 times a month': '8 раз в месяц',
        '2 hours a day': '2 часа в день',
        '1 academic hour a day': '1 академический час в день',
        'BABYSITTER / NURSE': 'Няня / Сиделка',
        '20 days a month': '20 дней в месяц',
        '4 hours a day': '4 часа в день',
        '1 time a day': '1 раз в день',
        'for a month': 'на месяц',
        'hour': 'час',
        'hours1': 'часа',
        'hours2': 'часов'
    }
}