import React, { memo } from 'react'
import styles from './index.module.css'
import { ReactComponent as AdvList } from './images/advList.svg'
import { ReactComponent as AdvListTel } from './images/advListTel.svg'
import Text from '../../../i18n/text'

const Advantages = memo(() => {
    return (
        <section className={styles.section}>
            <div className={styles.wrapper}>
                <h1 className={styles.title}>
                    <Text id='Our advantages' />
                </h1>
                <hr className={styles.line} />
                <div>
                    <AdvListTel className={styles.advListTel} />
                    <div className={styles.text}>
                        <p><Text id='we are the owners of several telegram' /><br /></p>
                        <p><Text id='channels with a target audience of more' /><br /></p>
                        <p><Text id='than 10,000 subscribers' /><br /></p>
                    </div>
                    <div className={styles.text}>
                        <p><Text id='our team members have professional' /><br /></p>
                        <p><Text id='experience «in turnkey» construction' /><br /></p>
                    </div>
                    <div className={styles.text}>
                        <p><Text id='we cooperate with a large number of' /><br /></p>
                        <p><Text id='companies and specialists in various fields' /><br /></p>
                    </div>
                </div>
                <AdvList className={styles.advList} />
            </div>
        </section>
    )
})

export default Advantages