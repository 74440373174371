import React, { memo } from 'react'
import styles from './index.module.css'
import ButtonPartner from '../../../ButtonPartner'
import ButtonWork from '../../../ButtonWork'
import { ReactComponent as AboutImage } from './images/about.svg'
import { ReactComponent as LeftImage } from './images/leftImage.svg'
import { ReactComponent as RightImage } from './images/rightImage.svg'
import { ReactComponent as Support } from './images/support.svg'
import Text from '../../../i18n/text'

const Information = memo(() => {
    return (
        <section className={styles.section}>
            <div className={styles.bar}>
                <h1 className={styles.leftWrapper}>
                    <Text id='Some information about us' />
                </h1>
                <h1 className={styles.leftWrapperTel}>
                    <Text id='About us' />
                </h1>
                <div className={styles.rightWrapper}>
                    <div className={styles.button}>
                        <div className={styles.buttonLink}>
                            <ButtonPartner />
                        </div>
                        <div className={styles.buttonLink}>
                            <ButtonWork />
                        </div>
                    </div>
                    <AboutImage className={styles.aboutImage} />
                </div>
            </div>
            <div className={styles.about}>
                <div className={styles.titleBox}>
                    <LeftImage className={styles.leftImage} />
                    <h1 className={styles.title}>
                        <Text id='Why us?' />
                    </h1>
                    <RightImage className={styles.rightImage} />
                </div>
                <p className={styles.text}>
                    <Text id='About1' /><br />
                    <br />
                    <Text id='Areas of work:' /><br />
                    <Text id='About2' />
                </p>
            </div>
            <div className={styles.questions}>
                <div>
                    <h1 className={styles.helpTitle}>
                        <Text id='Have more questions?' />
                    </h1>
                    <p className={styles.helpText}>
                        <Text id='QuestionsText' />
                    </p>
                    <div className={styles.helpBox}>
                        <a href="https://t.me/manager_ipgeorgia" className={styles.helpLink} target="_blank_">
                            <span className={styles.helpLinkText}>
                                <Text id='Telegram Support' />
                            </span>
                        </a>
                        <p className={styles.or}>
                            <Text id='or' />
                        </p>
                        <a href="https://hitechbusinesshub.com/" className={styles.helpLink} target="_blank_">
                            <span className={styles.helpLinkText}>
                                <Text id='Our main Website' />
                            </span>
                        </a>
                    </div>
                </div>
                <Support className={styles.support} />
            </div>
        </section>
    )
})

export default Information