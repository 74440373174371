import React, { memo } from 'react'
import Home from '../Home'
import Catalog from '../Catalog'
import Privacy from '../Privacy'
import ScrollToTop from '../ScrollToTop'

import {
    BrowserRouter,
    Routes,
    Route,
} from 'react-router-dom'

const App = memo(() => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/catalog" element={<Catalog />} />
            </Routes>
        </BrowserRouter>
    )
})

export default App