import React, { memo } from 'react'
import styles from './index.module.css'
import ButtonPartner from '../../../ButtonPartner'
import ButtonWork from '../../../ButtonWork'
import { ReactComponent as Background } from './images/background.svg'
import { ReactComponent as BackgroundTel } from './images/backgroundTel.svg'
import { ReactComponent as Builder } from './images/builder1.svg'
import { ReactComponent as BuilderBig } from './images/builderBig.svg'
import { ReactComponent as Cart } from './images/cart.svg'
import { Link } from 'react-router-dom'

import Text from "../../../i18n/text"

const FirstScreen = memo(() => {
    return (
        <section className={styles.section}>
            <Link to="/catalog" className={styles.catalogButton}>
                <Cart className={styles.catalogImg} /><p className={styles.catalogLink}><Text id='Catalog' /></p>
            </Link>
            <Background className={styles.background} />
            <BackgroundTel className={styles.backgroundTel} />
            <div className={styles.wrapper}>
                <div className={styles.leftWrapper}>
                    <h1 className={styles.title}>
                        <span className={styles.coloredText}><Text id='Easier' /></span> <Text id='than' /><br />
                        <Text id='You think!' /><br />
                        <span className={styles.coloredText}><Text id='More accessible' /></span> <Text id='than' /><br />
                        <Text id='You are expecting!' />
                    </h1>
                    <p className={styles.text}>
                        <Text id='A lot of specialists in just one click!' />
                    </p>
                    <div className={styles.button}>
                        <div className={styles.buttonLink}>
                            <ButtonPartner />
                        </div>
                        <div className={styles.buttonLink}>
                            <ButtonWork />
                        </div>
                    </div>
                </div>
                <BuilderBig className={styles.rightWrapper} />
                <Builder className={styles.builder} />
            </div>
        </section>
    )
})

export default FirstScreen