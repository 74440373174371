import React, { memo } from "react"
import styles from './index.module.css'
import ButtonPartner from '../ButtonPartner'
import ButtonWork from "../ButtonWork"
import { ReactComponent as Logo } from './images/GerviceLogo.svg'
import { Link } from 'react-router-dom'
import Text from "../i18n/text"

const Footer = memo(() => {
    return (
        <section className={styles.section}>
            <div className={styles.footer}>
                <div className={styles.block}>
                    <h2 className={styles.title}>
                        <Text id='Our social networks' />
                    </h2>
                    <a href="https://www.linkedin.com/company/gervice/" target='_blank_' className={styles.blockLink}>
                        LinkedIn
                    </a>
                    <a href="https://www.instagram.com/gervice2022/" target='_blank_' className={styles.blockLink}>
                        Instagram
                    </a>
                    <a href="https://www.facebook.com/Gervice-106933258815264" target='_blank_' className={styles.blockLink}>
                        Facebook
                    </a>
                </div>
                <div className={styles.block}>
                    <h2 className={styles.title}>
                        <Text id='Place an order right now' />
                    </h2>
                    <span className={styles.text}>
                        <Text id='OrderText' />
                    </span>
                    <div className={styles.button}>
                        <div className={styles.buttonLink}>
                            <ButtonPartner />
                        </div>
                        <div className={styles.buttonLink}>
                            <ButtonWork />
                        </div>
                    </div>
                </div>
                <div className={styles.logoBlock}>
                    <Logo className={styles.logo} />
                    <span className={styles.logoText}>
                        <Text id='LogoText' />
                    </span>
                </div>

            </div>
            <div className={styles.privacy}>
                <span className={styles.privacyText}>
                    Gervice ID: 302214574 <Link to="/privacy" target="_blank_" className={styles.privacyLink}>Privacy policy</Link>
                </span>
                <span>
                    <a href="https://drive.google.com/file/d/18ggMT7R7t5gNaDKk5IFiYHzAsnSojI0S/view" target="_blank_" className={styles.privacyLink}>
                        Public Offer Agreement
                    </a>
                </span>
            </div>
        </section>
    )
})

export default Footer