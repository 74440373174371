import Text from "../../../../i18n/text"

const services = [
    {
        name: <Text id='Plumbing works' />,
        description: <Text id='Calling a specialist for minor plumbing work' />,
        price: 40,
        max: 4,
    },
    {
        name: <Text id='Electrician' />,
        description: <Text id='Calling a specialist for minor electrical work' />,
        price: 45,
        max: 4,
    },
    {
        name: <Text id='Cook' />,
        description: <Text id='Purchase of products and preparation of three dishes' />,
        price: 150,
        max: 1,
    },
    {
        name: <Text id='Help around the house' />,
        description: <Text id='Calling a specialist to provide assistance around the house' />,
        price: 50,
        max: 8,
    },
    {
        name: <Text id='Loader' />,
        description: <Text id='Calling one specialist and ascent / descent to one floor' />,
        price: 45,
        max: 1,
    },
    {
        name: <Text id='Seamstress' />,
        description: <Text id='Small work (shorten trousers, insert zipper)' />,
        price: 20,
        max: 1,
    },
    {
        name: <Text id='Tutor / Music teacher / Speech therapist / Defectologist' />,
        description: <Text id='without an academic degree' />,
        price: 60,
        max: 6,
    },
    {
        name: <Text id='Tutor / Music teacher / Speech therapist / Defectologist' />,
        description: <Text id='with an academic degree' />,
        price: 90,
        max: 6,
    },
    {
        name: <Text id='Babysitter' />,
        description: <Text id='Calling a specialist for child care' />,
        price: 60,
        max: 8,
    },
    {
        name: <Text id='Nurse' />,
        description: <Text id='Calling a specialist to take care of a person' />,
        price: 60,
        max: 8,
    },
    {
        name: <Text id='Masseur' />,
        description: <Text id='Providing a full range of massage services' />,
        price: 90,
        max: 3,
    }
]

export default services