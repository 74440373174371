import React, { memo } from 'react'
import styles from './index.module.css'
import Burger from '../Burger'
import ButtonPartner from '../../../ButtonPartner'
import Text from '../../../i18n/text'

const Title = memo(() => {
    return (
        <section className={styles.section}>
            <div className={styles.wrapper}>
                <div className={styles.selectorWrapper}>
                    <p className={styles.text}>
                        <Text id='CatalogMes1' />
                    </p>
                    <div className={styles.button}>
                        <ButtonPartner />
                    </div>
                    <p className={styles.additional}>
                        <Text id='CatalogMes2' />
                    </p>
                </div>
                <Burger />
            </div>
        </section>
    )
})

export default Title