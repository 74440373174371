import React, { memo } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import FirstScreen from './sections/FirstScreen'
import Advantages from './sections/Advantages'
import Information from './sections/Information'

const Home = memo(() => {
    return (
        <div style={{ overflow: 'hidden' }}>
            <body>
                <Header />
                <FirstScreen />
                <Advantages />
                <Information />
                <Footer />
            </body>
        </div>
    )
})

export default Home