import styles from './index.module.css'
import servicesMonth from './servicesMonth'
import { ReactComponent as Check } from './check.svg'
import Text from '../../../../i18n/text'

const CardMonth = ({ id }: { id: number }) => {
    return (
        <div>
            <div className={styles.background} />
            <div className={styles.card}>
                <div className={styles.cardWrapper}>
                    <h1 className={styles.cardTitle}>{servicesMonth[id].name}</h1>
                    <p className={styles.description}><Text id='for a month' /></p>
                    <span className={styles.cardPrice}>{servicesMonth[id].price} <Text id='GEL' /></span>
                    <div className={styles.textWrapper}>
                        <div className={styles.cardText}>
                            <Check className={styles.img} />
                            <p className={styles.text}>
                                {servicesMonth[id].description1}
                            </p>
                        </div>
                        <div className={styles.cardText}>
                            <Check className={styles.img} />
                            <p className={styles.text}>
                                {servicesMonth[id].description2}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardMonth