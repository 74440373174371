import React, { memo, useState, useCallback, useEffect } from 'react'
import styles from './index.module.css'
import { ReactComponent as Logo } from './images/GerviceLogo.svg'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { en, ru } from '../../actions'
import { ReactComponent as Rus } from './images/rus.svg'
import { ReactComponent as Eng } from './images/eng.svg'
import { useSelector } from 'react-redux'

const Header = memo(() => {
    const dispatch = useDispatch()

    const [selectedLang, setSelectedLang] = useState(useSelector(n => n) === 'en-US' ? true : false)
    const [headerBack, setHeaderBack] = useState(false);

    const onScroll = useCallback(() => {
        if (window.scrollY > 400) {
            setHeaderBack(true)
        } else {
            setHeaderBack(false)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', onScroll)

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [onScroll])

    const selectChangeENG = () => {
        setSelectedLang(true)
        dispatch(en())
    };

    const selectChangeRU = () => {
        setSelectedLang(false)
        dispatch(ru())
    };

    return (
        <section className={headerBack ? styles.headerBack : styles.header}>
            <div className={styles.wrapper}>
                <Link to="/"><Logo className={styles.logo} /></Link>
                <div onClick={selectChangeRU} className={selectedLang ? styles.langSelect : styles.langNoSelect}>
                    <Eng className={styles.flag} />ENG
                </div>
                <div onClick={selectChangeENG} className={selectedLang ? styles.langNoSelect : styles.langSelect}>
                    <Rus className={styles.flag} />РУС
                </div>
            </div>
        </section>
    )
})

export default Header