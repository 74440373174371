import React, { memo } from "react"
import styles from './index.module.css'
import Card from '../Cards/Card'
import CardCleaning from '../Cards/CardCleaning'
import CardMonth from '../Cards/CardMonth'
import Text from "../../../i18n/text"

const CatalogMenu = memo(() => {
    return (
        <section className={styles.section}>
            <h1 id='Cleaning services' className={styles.title}><Text id='Cleaning services' /></h1>
            <div className={styles.line} />
            <div className={styles.catalog}>
                <div className={styles.wrapper}>
                    <CardCleaning id={0} />
                </div>
                <div className={styles.wrapper}>
                    <CardCleaning id={1} />
                </div>
            </div>
            <h1 id='Household services' className={styles.title}><Text id='Household services' /></h1>
            <div className={styles.line} />
            <div className={styles.catalog}>
                <div className={styles.wrapper}>
                    <Card id={0} />
                </div>
                <div className={styles.wrapper}>
                    <Card id={1} />
                </div>
                <div className={styles.wrapper}>
                    <Card id={2} />
                </div>
                <div className={styles.wrapper}>
                    <Card id={3} />
                </div>
                <div className={styles.wrapper}>
                    <Card id={4} />
                </div>
                <div className={styles.wrapper}>
                    <Card id={5} />
                </div>
            </div>
            <h1 id='Additional education' className={styles.title}><Text id='Additional education' /></h1>
            <div className={styles.line} />
            <div className={styles.catalog}>
                <div className={styles.wrapper}>
                    <Card id={6} />
                </div>
                <div className={styles.wrapper}>
                    <Card id={7} />
                </div>
            </div>
            <h1 id='Care services' className={styles.title}><Text id='Care services' /></h1>
            <div className={styles.line} />
            <div className={styles.catalog}>
                <div className={styles.wrapper}>
                    <Card id={8} />
                </div>
                <div className={styles.wrapper}>
                    <Card id={9} />
                </div>
                <div className={styles.wrapper}>
                    <Card id={10} />
                </div>
            </div>
            <h1 id='Monthly services' className={styles.title}><Text id='Monthly services' /></h1>
            <div className={styles.line} />
            <div className={styles.catalog}>
                <div className={styles.wrapper}>
                    <CardMonth id={0} />
                </div>
                <div className={styles.wrapper}>
                    <CardMonth id={1} />
                </div>
                <div className={styles.wrapper}>
                    <CardMonth id={2} />
                </div>
                <div className={styles.wrapper}>
                    <CardMonth id={3} />
                </div>
                <div className={styles.wrapper}>
                    <CardMonth id={4} />
                </div>
                <div className={styles.wrapper}>
                    <CardMonth id={5} />
                </div>
                <div className={styles.wrapper}>
                    <CardMonth id={6} />
                </div>
                <div className={styles.wrapper}>
                    <CardMonth id={7} />
                </div>
            </div>
        </section>
    )
})

export default CatalogMenu