export const en = () => {
    return {
        type: 'EN'
    }
}

export const ru = () => {
    return {
        type: 'RU'
    }
}