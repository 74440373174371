const switcher = (state = 'en-US', action) => {
    switch (action.type) {
        case 'EN':
            return state = 'en-US'
        case 'RU':
            return state = 'ru-RU'
        default:
            return state
    }
}

export default switcher