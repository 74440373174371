import Text from "../../../../i18n/text"

const servicesCleaning = [
    {
        name: <Text id='REGULAR CLEANING' />,
        description: <Text id='For an apartment of up to 60 square meters' />,
        price: 90,
        max: 1,
        titles: [
            <Text id='THE WHOLE APARTMENT' />,
            <Text id='KITCHEN' />,
            <Text id='BATHROOM / TOILET' />
        ],
        page1: 
        [
            <Text id='Dust off the walls and ceiling' />,
            <Text id='Remove dust and eliminate dirt on all surfaces' />,
            <Text id='Dust off and wash the lighting fixtures' />,
            <Text id='Wash the floor' />,
            <Text id='Vacuum the carpet' />,
            <Text id='Clean household appliances outside' />,
            <Text id='Wash the mirrors' />,
        ],
        page2: 
        [
            <Text id='Wash the entrance and interior doors' />,
            <Text id='Wash the furniture' />,
        ],
        page3: 
        [
            <Text id='Wash the sink' />,
            <Text id='Wash the countertop' />,
            <Text id='Wash the cabinets from the inside' />,
            <Text id='Wash the stove' />,
        ],
        page4:
        [
            <Text id='Eliminate traces of rust, water stone and lime plaque' />,
            <Text id='Wash the tile and clean the seams' />,
        ]
    },
    {
        name: <Text id='GENERAL CLEANING' />,
        description: <Text id='For an apartment of up to 60 square meters' />,
        price: 140,
        max: 1,
        titles: [
            <Text id='THE WHOLE APARTMENT' />,
            <Text id='KITCHEN' />,
            <Text id='BATHROOM / TOILET' />
        ],
        page1: 
        [
            <Text id='Dust off the walls and ceiling' />,
            <Text id='Remove dust and eliminate dirt on all surfaces' />,
            <Text id='Dust off and wash the lighting fixtures' />,
            <Text id='Wash the floor' />,
            <Text id='Vacuum the carpet' />,
            <Text id='Clean household appliances outside' />,
            <Text id='Wash the mirrors' />,
        ],
        page2:
        [
            <Text id='Wash the entrance and interior doors' />,
            <Text id='Wash the furniture' />,
            <Text id='Eliminate local contamination on the walls' />,
            <Text id='Make the bed' />,
            <Text id='Clean the glazed balcony, loggia (vacuum, wipe the dust)' />,
            <Text id='Wash the windows (one window, the rest – for an additional fee)' />,
        ],
        page3:
        [
            <Text id='Wash the sink' />,
            <Text id='Wash the countertop' />,
            <Text id='Wash the cabinets from the inside' />,
            <Text id='Wash the stove/eliminate traces of rust' />,
            <Text id='Wash the refrigerator without defrosting' />,
            <Text id='Clean the hood/eliminate traces of grease' />,
        ],
        page4:
        [
            <Text id='Eliminate traces of rust, water stone and lime plaque' />,
            <Text id='Wash the tile and clean the seams' />,
        ]
    }
]

export default servicesCleaning