import { LOCALES } from '../constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    [LOCALES.ENGLISH]: {
        'Easier': 'Easier',
        'than': 'than',
        'You think!': 'You think!',
        'More accessible': 'More accessible',
        'You are expecting!': 'You are expecting!',
        'A lot of specialists in just one click!': 'A lot of specialists in just one click!',
        'Catalog': 'Catalog',

        'Become a client': 'Become a client',
        'Get a job': 'Get a job',

        'Our advantages': 'Our advantages',
        'we are the owners of several telegram': 'we are the owners of several telegram',
        'channels with a target audience of more': 'channels with a target audience of more',
        'than 10,000 subscribers': 'than 10,000 subscribers',
        'our team members have professional': 'our team members have professional',
        'experience «in turnkey» construction': 'experience «in turnkey» construction',
        'we cooperate with a large number of': 'we cooperate with a large number of',
        'companies and specialists in various fields': 'companies and specialists in various fields',

        'Some information about us': 'Some information about us',
        'About us': 'About us',
        'Why us?': 'Why us?',
        'About1': 'Our team unites specialists of various fields of activity. We promote high- quality construction works and services on the Georgian market together with our partners. Also, all kinds of services for organizing a comfortable life in Georgia. We select specialists for customer requests. We are engaged in the development of information technologies for the organization of high- quality services in the field of comprehensive household services.',
        'Areas of work:': 'Areas of work:',
        'About2': 'Everything related to construction works and services. Services for organizing a comfortable life in Georgia: in the field of construction, repair, cleaning, housework(electrician, plumber, help around the house, cook, seamstress, loader, etc.), additional education and service(tutors, vocals, music, nurses, babysitters), IT schools, Detentions etc. As well as informational and legal support various business projects and their support using information technology.',
        'Have more questions?': 'Have more questions?',
        'Information': 'Our team unites specialists of various fields of activity. We promote high- quality construction works and services on the Georgian market together with our partners.Also, all kinds of services for organizing a comfortable life in Georgia.We select specialists for customer requests. We are engaged in the development of information technologies for the organization of high- quality services in the field of comprehensive household services.',
        'QuestionsText': 'You can visit our main website, or contact our technical support team and clarify all the details you are interested in',
        'Telegram Support': 'Telegram Support',
        'or': 'or',
        'Our main Website': 'Our main Website',

        'Our social networks': 'Our social networks',
        'Place an order right now': 'Place an order right now',
        'OrderText': 'We provide our clients and partners with an operational search for the necessary specialists who perform the work efficiently, on time and at reasonable prices',
        'LogoText': 'Gervice — high-quality service in the service sector',

        'CatalogMes1': "We have developed for you the direction Gervice - Organization of a comfortable life in Georgia! Gervice provides various services to organize your comfortable life in Georgia. It's like household services at home: Cleaning, Master for an hour, Minor repairs, Household help, Babysitting and nurse services, Cooking, Services of tutors, speech therapists, defectologists, Massage, exercise therapy and other services that you need.So are services in the field of additional education and maintenance: IT - school, Detention and other services.We select the best specialists for you and organize their work, guarantee the quality of services so that you do not have to think about how to comfortably organize your business and your life in Georgia. If at the moment we do not have the necessary specialist in the requested direction in your region, we will definitely select him personally for you!",
        'CatalogMes2': '*The cost of construction services and other services not listed in the Price List is determined by individual agreement with the client',
        'Cleaning services': 'Cleaning services',
        'Household services': 'Household services',
        'Additional education': 'Additional education',
        'Care services': 'Care services',
        'Monthly services': 'Monthly services',
        'Menu': 'Menu',

        'REGULAR CLEANING': 'REGULAR CLEANING',
        'GENERAL CLEANING': 'GENERAL CLEANING',
        'For an apartment of up to 60 square meters': 'For an apartment of up to 60 square meters',
        'THE WHOLE APARTMENT': 'THE WHOLE APARTMENT',
        'KITCHEN': 'KITCHEN',
        'BATHROOM / TOILET': 'BATHROOM / TOILET',
        'Dust off the walls and ceiling': 'Dust off the walls and ceiling',
        'Remove dust and eliminate dirt on all surfaces': 'Remove dust and eliminate dirt on all surfaces',
        'Dust off and wash the lighting fixtures': 'Dust off and wash the lighting fixtures',
        'Wash the floor': 'Wash the floor',
        'Vacuum the carpet': 'Vacuum the carpet',
        'Clean household appliances outside': 'Clean household appliances outside',
        'Wash the mirrors': 'Wash the mirrors',
        'Wash the entrance and interior doors': 'Wash the entrance and interior doors',
        'Wash the furniture': 'Wash the furniture',
        'Wash the sink': 'Wash the sink',
        'Wash the countertop': 'Wash the countertop',
        'Wash the cabinets from the inside': 'Wash the cabinets from the inside',
        'Wash the stove': 'Wash the stove',
        'Eliminate traces of rust, water stone and lime plaque': 'Eliminate traces of rust, water stone and lime plaque',
        'Wash the tile and clean the seams': 'Wash the tile and clean the seams',
        'Eliminate local contamination on the walls': 'Eliminate local contamination on the walls',
        'Make the bed': 'Make the bed',
        'Clean the glazed balcony, loggia (vacuum, wipe the dust)': 'Clean the glazed balcony, loggia (vacuum, wipe the dust)',
        'Wash the windows (one window, the rest – for an additional fee)': 'Wash the windows (one window, the rest – for an additional fee)',
        'Wash the stove/eliminate traces of rust': 'Wash the stove/eliminate traces of rust',
        'Wash the refrigerator without defrosting': 'Wash the refrigerator without defrosting',
        'Clean the hood/eliminate traces of grease': 'Clean the hood/eliminate traces of grease',
        'DETAILS': 'DETAILS',
        'Additional services:': 'Additional services:',
        'Washing dishes: 30 minutes - 20 GEL': 'Washing dishes: 30 minutes - 20 GEL',

        'GEL': 'GEL',

        'Plumbing works': 'Plumbing works',
        'Calling a specialist for minor plumbing work': 'Calling a specialist for minor plumbing work',
        'Electrician': 'Electrician',
        'Calling a specialist for minor electrical work': 'Calling a specialist for minor electrical work',
        'Cook': 'Cook',
        'Purchase of products and preparation of three dishes': 'Purchase of products and preparation of three dishes',
        'Help around the house': 'Help around the house',
        'Calling a specialist to provide assistance around the house': 'Calling a specialist to provide assistance around the house',
        'Loader': 'Loader',
        'Calling one specialist and ascent / descent to one floor': 'Calling one specialist and ascent / descent to one floor',
        'Seamstress': 'Seamstress',
        'Small work (shorten trousers, insert zipper)': 'Small work (shorten trousers, insert zipper)',
        'Tutor / Music teacher / Speech therapist / Defectologist': 'Tutor / Music teacher / Speech therapist / Defectologist',
        'without an academic degree': 'without an academic degree',
        'with an academic degree': 'with an academic degree',
        'Babysitter': 'Babysitter',
        'Calling a specialist for child care': 'Calling a specialist for child care',
        'Nurse': 'Nurse',
        'Calling a specialist to take care of a person': 'Calling a specialist to take care of a person',
        'Masseur': 'Masseur',
        'Providing a full range of massage services': 'Providing a full range of massage services',

        'CLEANING': 'CLEANING',
        '4 regular cleanings': '4 regular cleanings',
        'CLEANING Plus': 'CLEANING Plus',
        '1 general cleaning + 4 regular cleanings': '1 general cleaning + 4 regular cleanings',
        '8 times a month': '8 times a month',
        '2 hours a day': '2 hours a day',
        '1 academic hour a day': '1 academic hour a day',
        'BABYSITTER / NURSE': 'BABYSITTER / NURSE',
        '20 days a month': '20 days a month',
        '4 hours a day': '4 hours a day',
        '1 time a day': '1 time a day',
        'for a month': 'for a month',

        'hour': 'hour',
        'hours1': 'hours',
        'hours2': 'hours'
    }
}
