import React, { Fragment } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import messages from './messages'
import { useSelector } from 'react-redux'

const Text = ({ id }) => {
    const locale = useSelector(n => n)

    return (
    <IntlProvider
        textComponent={Fragment}
        locale={locale}
        messages={messages[locale]}
    >
        <FormattedMessage id={id} />
    </IntlProvider>
    )
}

export default Text;